@import "./vars.less";

#root,
.main__wrapper {
  min-height: 550px;
  height: 100%;
  overflow: hidden;
}

.ant-layout {
  background-color: #ffffff !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none !important;
}
