.logo {
  height: 32px;
  margin: 16px;
  background: #d5eaff;
}

.ant-layout-sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  font-weight: bold;
  .ant-menu {
    padding: 0 10px;
    .ant-menu-item {
      border-radius: 6px;
      .ant-badge {
        color: inherit !important;
      }
      .anticon {
        font-size: 16px;
      }
      .title {
        margin-left: 12px;
      }
    }
  }
  .margin-menu {
    margin-top: 30px;
  }
  .margin-title {
    margin-left: 26px;
  }
}

@border-radius-base: 6px;