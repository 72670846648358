@import "./vars.less";

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb:vertical {
  margin: 5px;
  background-color: #999;
  -webkit-border-radius: 5px;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  height: 5px;
  display: block;
}
