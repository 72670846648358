.app__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  top: 0;
  left: 0;
}

.app__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -13px;
}

@border-radius-base: 6px;